
import { ChevronRightIcon } from '@heroicons/vue/outline';
import store from '@/store';
import SystemStatusBadge from './SystemStatusBadge.vue';

export default {
  name: 'OrganizationSubNav',
  components: {
    ChevronRightIcon,
    SystemStatusBadge,
  },
  setup() {
    const sharedState = store.state;

    return {
      sharedState,
    };
  },
};
